import { enumToOption } from '@/utils'

// 备案状态
export enum contractStatus {
  合同生效 = 1,
  提前终止,
  作废,
}

export const contractStatusOpt = enumToOption(contractStatus)

// 提交状态
export enum submitStatus {
  待提交 = 1,
  成功,
  失败,
  取消,
}

export const submitStatusOpt = enumToOption(submitStatus)

// 申请状态
export enum transferStatus {
  待申请转移 = 1,
  无待转移违章 = 2,
  已申请待审核 = 3,
  审核失败 = 4,
  完成违法分离 = 5,
  拟将作出人车分离 = 6,
  撤销拟定人车分离 = 7,
  人车分离后撤销 = 8,
  待初审 = 9,
  初审退回 = 10
}

export const transferStatusOpt = enumToOption(transferStatus)

// import { enumToOption } from '@/utils/index'
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: false,
    // table 索引 label
    indexLabel: '编号',
    // table index索引属性或方法 number, Function(index) 注意可以赋值 number 或是传入一个 function
    indexMethod: 1,
    // table 工具设置选项 配置则默认开启
    tool: null,
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 100,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'companyName',
      label: '企业名称',
      width: '140'
    },
    {
      key: 'contractSubNo',
      label: '合同编号',
      width: '140'
    },
    {
      key: 'orgName',
      label: '备案资产名称',
      width: '140'
    },
    {
      key: 'carNo',
      label: '车牌号',
      width: '100'
    },
    {
      key: 'driverName',
      label: '司机姓名',
      width: '100'
    },
    {
      key: 'contractStartTime',
      label: '合同开始日期',
      width: '100',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'contractEndTime',
      label: '合同到期日期',
      width: '100',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'contractStatus',
      label: '备案状态',
      width: '110',
      formatter: (row, col, val) => contractStatus[val] || '-'
    },
    {
      key: 'transferStatus',
      label: '申请状态',
      width: '110',
      formatter: (row, col, val) => transferStatus[val] || '-'
    },
    {
      key: 'backReason',
      label: '审核失败原因',
      width: '110',
      formatter: (row, col, val) => val || ''
    },
    {
      key: 'submitMaterialTime',
      label: '提交时间',
      width: '130',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'action',
      label: '操作',
      width: '110',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

export const inputList = (remoteData: any) => [
  {
    type: 'select',
    label: '企业名称',
    key: 'companyUuid',
    placeholder: '请输入',
    labelWidth: 110,
    inputWidth: 200,
    options: remoteData.optionsVin,
    isLoading: remoteData.isLoading,
    isRemote: true,
    isFilterable: true,
    remoteMethod: (v) => remoteData.remoteMethod.value(v, 1)
  },
  {
    type: 'input',
    label: '司机名称',
    key: 'driverName',
    placeholder: '请输入',
    labelWidth: 110,
    inputWidth: 200
  },
  {
    type: 'input',
    label: '车牌号',
    key: 'carNo',
    placeholder: '请输入',
    labelWidth: 110,
    inputWidth: 200
  },
  {
    type: 'select',
    label: '申请状态',
    key: 'transferStatus',
    placeholder: '请选择',
    labelWidth: 110,
    inputWidth: 200,
    options: transferStatusOpt,
    rules: {
      type: 'number'
    }
  },
  {
    type: 'datePicker',
    dateType: 'daterange',
    label: '提交时间',
    key: 'contractStartTime',
    placeholder: '请输入',
    format: 'YYYY-MM-DD',
    itemShow: true,
    labelWidth: 110,
    inputWidth: 280,
    isArea: true, // 是否开启这功能，默认关闭，兼容以前
    keyList: ['submitMaterialTimeStart', 'submitMaterialTimeTimeEnd'], // 开始，结束时间字段
    formatList: ['YYYY-MM-DD 00:00:00', 'YYYY-MM-DD 23:59:59'], // 开始，结束时间格式
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期'
  },
]

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    rowData: null // 单行数据
  }

  return {
    ...page
  }
}
