/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-05-05 10:46:13
 * @LastEditors: panyufeng
 * @LastEditTime: 2025-03-21 16:54:57
 */

import service from '@/utils/request'

export const getListAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/violationContractCheck/page',
    data: params
  })

// 企业下拉
export const getCompanyListAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/violationContractCheck/getCompanyList',
    data: params
  })

// 违章转移详情
export const subInfoAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/violationContractCheck/subInfo',
    data: pramas
  })

// 合同操作记录
export const violationContractOperateLogListAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/violationContractOperateLog/list',
    data: pramas
  })

// 提交资料审核
export const checkSubmitMaterialAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/violationContractCheck/checkSubmitMaterial',
    data: pramas
  })

// 待初审个数
export const getTransferCount = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/violationContractCheck/getTransferCount',
    data: pramas
  })
