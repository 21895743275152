
import CTable from '@/components/c-table'
import { getTableConfig, getIndexConfig, inputList } from './config'
import CSearchList from '@/components/c-search-list'
import { defineComponent, onActivated, reactive, toRefs } from 'vue'
import { getListAPI, getTransferCount } from './api'
import { btnName } from '@/utils/index'
import { toResult } from '@/utils/toResult'
import { useRouter } from 'vue-router'
import { getCompanyListAPI } from './api'

export default defineComponent({
  name: 'transfer-application-review-query',
  components: {
    CSearchList,
    CTable,
  },

  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig(),
    })
    const queryByCarNo = async (query, callback) => {
      const [err, res] = await toResult(getCompanyListAPI({ value: query }))
      if (err) return
      const options = res.map((i) => ({
        label: i.companyName,
        value: i.companyUuid,
      }))
      callback(options)
    }

    const remoteData = reactive({
      isLoading: false,
      optionsContract: [],
      optionsDriver: [],
      optionsVin: [],
      remoteMethod: (query: string, type: number) => {
        remoteData.isLoading = true
        if (query !== '') {
          switch (type) {
            case 1:
              queryByCarNo(query, (options) => {
                remoteData.optionsVin = []
                remoteData.isLoading = false
                remoteData.optionsVin = options
              })
              break
          }
        } else {
          remoteData.isLoading = false
        }
      },
    })

    // 页面基础变量
    const data = reactive({
      inputList: inputList({ ...toRefs(remoteData) }),
      ...getIndexConfig(),
      contractTypeNum: 0,
    })

    // 列表各违章类型数量
    const getContractTypeNumFn = async () => {
      const [err, res] = await toResult(getTransferCount({}))
      if (err) return
      data.contractTypeNum = res.value
    }
    getContractTypeNumFn()
    // 列表数据
    const getListFn = async () => {
      const params = {
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize,
      }
      tableConfig.loading = true
      const obj = Object.assign(params, data.queryForm)
      const [err, res] = await toResult(getListAPI(obj))
      tableConfig.loading = false
      if (err) return
      tableConfig.data = res.list || []
      tableConfig.pagination.total = res.total || 0
    }

    // 表单设置页数
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    // 表单翻页
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }

    // 执行搜索
    const handleSearch = (params: object) => {
      data.queryForm = params
      getListFn()
    }
    // 执行重置
    const handleReset = (params: object) => {
      data.queryForm = params
      getListFn()
    }

    // keep-alive 缓存更新
    onActivated(() => {
      handleSearch(data.queryForm)
      getContractTypeNumFn()
    })

    const tabClick = (item) => {
      console.log(item.props.name)

      if (item.props.name === 1) {
        tableConfig.pagination.currentPage = 1
        const params = { transferStatus: 9 }
        params && (data.queryForm = { ...params })
        getListFn()
      } else {
        tableConfig.pagination.currentPage = 1
        if ('transferStatus' in data.queryForm) {
          delete data.queryForm['transferStatus']
        }
        getListFn()
      }
    }

    const router = useRouter()
    const handleAction = (type: string, row: any) => {
      switch (type) {
        case '详情': {
          router.push({
            name: 'transfer-application-review-detail',
            query: {
              id: row.id,
            },
          })
          break
        }
      }
    }

    return {
      ...toRefs(data),
      btnName,
      handleSearch,
      handleReset,
      getListFn,
      tableConfig,
      handleAction,
      pageCurrentChange,
      pageSizeChange,
      tabClick,
    }
  },
  mounted() {
    this.getListFn()
  },
})
